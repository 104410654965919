<template>
  <div id="hire-view">
    
  </div>
</template>

<script>
export default {
  name: 'Hire',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>